import Keycloak from 'keycloak-js'

let redirect_uris = {}
let client_id = "";
if(process.env.NODE_ENV === 'development'){
    redirect_uris = {
        "Coredata": "https://aicoredev2.coredata.ca/MCU",
        "Normandeau": "https://aicoredev2.coredata.ca/NormandeauDashboard",
        "Imec": "https://aicoredev2.coredata.ca/IMEC",
        "ImecCore": "https://aicoredev2.coredata.ca/ImecCoreUIService",
        "Gibson": "https://aicoredev2.coredata.ca/ImecCoreUIService",
        "SummitMidstream": "https://aicoredev2.coredata.ca/ImecCoreUIService",
        "SecureEnergy": "https://aicoredev2.coredata.ca/ImecCoreUIService"
    }

    client_id = "imeccorefms-dev";
}
else if(process.env.NODE_ENV === 'production'){
    redirect_uris = {
        "Coredata": "https://aicoreio.coredata.ca/MCU",
        "Normandeau": "https://aicoreio.coredata.ca/NormandeauDashboard",
        "Imec": "https://imec.coredata.ca",
        "ImecCore": "https://aicoreio.coredata.ca/ImecCoreUIService",
        "Gibson": "https://aicoreio.coredata.ca/ImecCoreUIService",
        "SummitMidstream": "https://aicoreio.coredata.ca/ImecCoreUIService",
        "SecureEnergy": "https://aicoreio.coredata.ca/ImecCoreUIService"
    }

    client_id = "imeccorefms";
}


let initOptions = {
    url: 'https://auth.aicore.ca', realm: 'imeccore', clientId: client_id, onLoad: 'login-required'
}
  
let keycloak = new Keycloak(initOptions);

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {

    if (!auth) {
        window.location.reload();
    } else {
        console.log("Authenticated");

        window.location.replace(redirect_uris[keycloak.idTokenParsed.groups[0]]);
    }

}).catch(() => {
    console.log("Authenticated Failed");
});